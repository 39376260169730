/*@font-face {*/
/*    font-family: 'Neueu';*/
/*    src: local('Neueu'), url('./Neue-Radial-A-Regular.ttf') format('truetype');*/
/*}*/

.ar-page {
    background-color: #181b25;
    min-height: 100vh;
}

.ar-danger-row {
    background-color: #ea4f5857;
}

.ar-danger-row:hover {
    background-color: rgba(234, 79, 88, 0.5) !important;
}

@media only screen and (min-width: 481px) {
    .ar-login-panel {
        width: 400px;
        min-height: 285px;
    }
}


.ar-three-quarter-hr {
    inline-size: 75% !important;
}

.ar-header {
    margin-top: 16px;
    margin-bottom: 16px;
}

.ar-horizontal-rule {
    height: 50px;
    width: 1px;
    background-color: #343741;
}

.ar-panel {
    background-color: #1f2330 !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}

.ar-panel-light {
    background-color: #30364b !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}

.echLegendItem:hover .background {
    background-color: #30364b;
}

.echTooltip {
    background-color: #30364b;
}

.echTooltip__tableRow--highlighted {
    background-color: #43485B;
}


.css-1k8ee5b-euiLoadingContent__singleLine::after {
    background: linear-gradient(
            137deg,
            #181b25 45%,
            #1f2330 50%,
            #181b25 55%
    ) !important;
}

.euiPopover__panel {
    background-color: #1f2330 !important;
}


.euiModal {
    background-color: #1f2330;
}

.euiTable {
    background-color: #1f2330;
}

.euiOverlayMask {
    background-color: rgba(13, 17, 24, 70%);
}

.euiTableRow.euiTableRow-isExpandedRow {
    background-color: #30364b;
}

.euiTableRow:hover {
    background-color: #30364b;
}

.euiTableFooterCell {
    background-color: #30364b;
}

.euiFilterGroup {
    background-color: #181b25;
}

.euiFilterGroup:focus {
    background-color: #101219;
}

.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
    background: #1f2330 no-repeat center;
}

.euiCheckbox:disabled {
    background-color: #2c303f;
}

.euiFieldSearch:disabled {
    background-color: #2c303f;
}

.euiFieldSearch {
    background-color: #181b25;
}

.euiFieldSearch:focus {
    background-color: #101219;
}

.euiFieldText:disabled {
    background-color: #2c303f;
}

.euiFieldText {
    background-color: #181b25;
}

.euiFieldText[readOnly] {
    background: #181b25;
}

.euiFieldText[readOnly]:focus {
    background: #101219;
}

.euiFieldText:focus {
    background-color: #101219;
}

.euiSelect {
    background-color: #181b25 !important;
}

.euiSelect:focus {
    background-color: #101219;
}

.euiSelect:disabled {
    background-color: #2c303f;
}

.euiSelect--compressed {
    background-color: #181b25;
}

.euiSelect--compressed:disabled {
    background-color: #2c303f;
}

.euiSelect--compressed:focus {
    background-color: #101219;
}

.euiSuperSelectControl {
    background-color: #181b25;
}

.euiSuperSelectControl:focus {
    background-color: #101219;
}

.euiSuperSelect__listbox {
    background-color: #30364b;
    border-radius: 10px;
}

.echChartBackground {
    background-color: #30364b !important;
    border-radius: 10px;
}

.euiSelectableList__list {
    background-color: #30364b;
}

/*.euiTable.euiTable--responsive .euiTableRow {*/
/*  background-color: #30364b;*/
/*  border-radius: 10px;*/
/*}*/


@media (max-width: 768px) {
    .euiTable.euiTable--responsive .euiTableRow {
        background-color: #30364b;
        border-radius: 10px;
    }
}

.euiTable.euiTable--responsive .euiTableRow:hover {
    background-color: #30364b;
}

.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow:hover {
    background-color: #30364b;
}

.euiFormControlLayout__prepend {
    background-color: #30364b !important;
}

.euiFormControlLayout__append {
    background-color: #30364b !important;
}

.euiSelectable {
    background-color: #30364b;
    border-radius: 10px;
}

.euiSelectableListItem:not(:last-of-type) {
    border-bottom: 1px solid #191b25;
}

.euiSuperSelect__item--hasDividers:not(:last-of-type) {
    border-bottom: 1px solid #191b25;
}

.euiSuperSelectControl:disabled {
    background-color: #2c303f;
}

.euiMarkdownEditorTextArea {
    background-color: #181b25;
}

.euiMarkdownEditorToolbar {
    background-color: #30364b;
}

.euiMarkdownEditorFooter {
    background-color: #30364b;
}

.euiCard {
    background-color: #30364b !important;
}

.euiCodeBlock {
    background-color: #30364b !important;
}

.euiCallOut--warning {
    border-radius: 6px;
    background-color: #5c4b0a !important;
}

.euiCallOut--primary {
    background-color: #468EAB !important;
    color: #141519 !important;
    border-radius: 6px;
}


.euiMarkdownEditorTextArea:focus,
.euiMarkdownEditor:focus-within .euiMarkdownEditorTextArea {
    background-color: #101219;
}

.euiSuperSelect__item:focus {
    background-color: #2c303f;
    color: #55b9dd;
}

.euiSelectableListItem-isFocused:not([aria-disabled="true"]),
.euiSelectableListItem:hover:not([aria-disabled="true"]) {
    background-color: #2c303f;
    color: #55b9dd;
}

.euiNotificationBadge {
    background: #62dea7 !important;
}

@media (max-width: 768px) {
    .form-row {
        max-width: 100% !important;
    }

    .ar-header {
        margin-left: 0;
        margin-right: 0;
    }
}

.form-row {
    min-width: 600px;
}

.help-button {
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    position: fixed;
    z-index: 9000;
    bottom: 0;
    max-height: 100vh;
    overflow-y: auto;
    padding: 32px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@media (max-width: 767px) {
    .center-mobile {
        align-items: center;
    }
}

/*.euiButtonEmpty--primary {*/
/*  color: #00c1e3;*/
/*}*/
/*.euiButtonEmpty--primary:focus {*/
/*  background-color: rgba(0, 193, 227, 0.1);*/
/*}*/

.react-datepicker {
    background-color: #30364b;
}

.euiTableRow-hasActions
.euiTableCellContent--showOnHover
.euiTableCellContent__hoverItem {
    opacity: unset;
    filter: unset;
}

.react-datepicker__month-read-view {
    background-color: #181b25;
}

.react-datepicker__month-dropdown {
    background-color: #181b25;
}

.react-datepicker__month-dropdown::-webkit-scrollbar-thumb {
    background-color: #181b25;
}

.react-datepicker__year-read-view {
    background-color: #181b25;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"]),
.react-datepicker__month-dropdown {
    background-color: #181b25;
}

.react-datepicker__year-dropdown {
    background-color: #181b25;
}

.react-datepicker__time-list {
    background-color: #181b25;
    border-radius: 10px;
}

.euiButtonEmpty--primary {
    color: #62dea7;
}

.euiButtonEmpty--primary:focus {
    background-color: rgba(9, 240, 136, 0.1);
}

.euiButtonEmpty--danger {
    color: #ea4f58;
}

.euiButtonEmpty--danger:focus {
    background-color: rgba(230, 35, 46, 0.1);
}

.euiButtonEmpty--disabled {
    color: #2c303f;
}

.euiButtonEmpty--disabled:focus {
    background-color: rgba(81, 87, 97, 0.1);
}

.euiButtonEmpty--disabled:hover {
    cursor: not-allowed;
}

.euiButtonEmpty--text {
    color: #dfe5ef;
}

.euiButtonEmpty--text:focus {
    background-color: rgba(223, 229, 239, 0.1);
}

.euiButtonEmpty--success {
    color: #62dea7;
}

.euiButtonEmpty--success:focus {
    background-color: rgba(9, 240, 136, 0.1);
}

.euiButtonEmpty--warning {
    color: #f3d371;
}

.euiButtonEmpty--warning:focus {
    background-color: rgba(255, 184, 26, 0.1);
}

/*.euiButton--primary {*/
/*  color: #00c1e3;*/
/*  background-color: rgba(0, 193, 227, 0.2);*/
/*}*/
/*.euiButton--primary:not([class*="isDisabled"]):hover,*/
/*.euiButton--primary:not([class*="isDisabled"]):focus {*/
/*  background-color: rgba(0, 193, 227, 0.2);*/
/*}*/

.euiButton--primary {
    color: #072216;
    background-color: #62dea7;
}

.euiButton--primary:not([class*="isDisabled"]):hover,
.euiButton--primary:not([class*="isDisabled"]):focus {
    background-color: #24bf7a;
}

.euiButton--primary.euiButton--fill:focus {
    outline-color: #fff;
}

.euiButton--primary.euiButton--fill:not([class*="isDisabled"]) {
    color: #000;
}

.euiButton--primary.euiButton--fill:not([class*="isDisabled"]),
.euiButton--primary.euiButton--fill:not([class*="isDisabled"]):hover,
.euiButton--primary.euiButton--fill:not([class*="isDisabled"]):focus {
    background-color: #00c1e3;
}

.euiButton--accent {
    color: #030b26;
    background-color: #55b9dd;
}

.euiButton--accent:not([class*="isDisabled"]):hover,
.euiButton--accent:not([class*="isDisabled"]):focus {
    background-color: #2593bb;
}

.euiButton--accent.euiButton--fill:focus {
    outline-color: #fff;
}

.euiButton--accent.euiButton--fill:not([class*="isDisabled"]) {
    color: #000;
}

.euiButton--accent.euiButton--fill:not([class*="isDisabled"]),
.euiButton--accent.euiButton--fill:not([class*="isDisabled"]):hover,
.euiButton--accent.euiButton--fill:not([class*="isDisabled"]):focus {
    background-color: #0cbdab;
}

.euiButton--success {
    color: #125436;
    background-color: #62dea7;
}

.euiButton--success:not([class*="isDisabled"]):hover,
.euiButton--success:not([class*="isDisabled"]):focus {
    background-color: #6d9e46;
}

.euiButton--success.euiButton--fill:focus {
    outline-color: #fff;
}

.euiButton--success.euiButton--fill:not([class*="isDisabled"]) {
    color: #000;
}

.euiButton--success.euiButton--fill:not([class*="isDisabled"]),
.euiButton--success.euiButton--fill:not([class*="isDisabled"]):hover,
.euiButton--success.euiButton--fill:not([class*="isDisabled"]):focus {
    background-color: #62dea7;
}

.euiButton--warning {
    color: #f3d371;
    background-color: rgba(255, 184, 26, 0.2);
}

.euiButton--warning:not([class*="isDisabled"]):hover,
.euiButton--warning:not([class*="isDisabled"]):focus {
    background-color: rgba(255, 184, 26, 0.2);
}

.euiButton--warning.euiButton--fill:focus {
    outline-color: #fff;
}

.euiButton--warning.euiButton--fill:not([class*="isDisabled"]) {
    color: #000;
}

.euiButton--warning.euiButton--fill:not([class*="isDisabled"]),
.euiButton--warning.euiButton--fill:not([class*="isDisabled"]):hover,
.euiButton--warning.euiButton--fill:not([class*="isDisabled"]):focus {
    background-color: #f3d371;
}

.euiButton--danger {
    color: #ea4f58;
    background-color: rgba(230, 35, 46, 0.1);
}

.euiButton--danger:not([class*="isDisabled"]):hover,
.euiButton--danger:not([class*="isDisabled"]):focus {
    background-color: rgba(230, 35, 46, 0.1);
}

.euiButton--danger.euiButton--fill:focus {
    outline-color: #fff;
}

.euiButton--danger.euiButton--fill:not([class*="isDisabled"]) {
    color: #000;
}

.euiButton--danger.euiButton--fill:not([class*="isDisabled"]),
.euiButton--danger.euiButton--fill:not([class*="isDisabled"]):hover,
.euiButton--danger.euiButton--fill:not([class*="isDisabled"]):focus {
    background-color: #ea4f58;
}

.euiButton--subdued {
    color: #8d919a;
    background-color: rgba(122, 127, 137, 0.2);
}

.euiButton--subdued:not([class*="isDisabled"]):hover,
.euiButton--subdued:not([class*="isDisabled"]):focus {
    background-color: rgba(122, 127, 137, 0.2);
}

.euiButton--subdued.euiButton--fill:focus {
    outline-color: #fff;
}

.euiButton--subdued.euiButton--fill:not([class*="isDisabled"]) {
    color: #000;
}

.euiButton--subdued.euiButton--fill:not([class*="isDisabled"]),
.euiButton--subdued.euiButton--fill:not([class*="isDisabled"]):hover,
.euiButton--subdued.euiButton--fill:not([class*="isDisabled"]):focus {
    background-color: #7a7f89;
}

.euiButton--ghost {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
}

.euiButton--ghost:not([class*="isDisabled"]):hover,
.euiButton--ghost:not([class*="isDisabled"]):focus {
    background-color: rgba(255, 255, 255, 0.2);
}

.euiButton--ghost.euiButton--fill:not([class*="isDisabled"]) {
    color: #000;
}

.euiButton--ghost.euiButton--fill:not([class*="isDisabled"]),
.euiButton--ghost.euiButton--fill:not([class*="isDisabled"]):hover,
.euiButton--ghost.euiButton--fill:not([class*="isDisabled"]):focus {
    background-color: #fff;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track {
    outline: 2px solid currentColor;
    outline-offset: 2px;
    border-color: #00c1e3;
}

.euiSwitch .euiSwitch__body {
    pointer-events: none;
    width: 44px;
    height: 20px;
    background-color: #00c1e3;
    display: inline-block;
    position: relative;
    border-radius: 20px;
    vertical-align: middle;
}

.euiSwitch.euiSwitch--compressed
.euiSwitch__button[aria-checked="true"]
.euiSwitch__thumb,
.euiSwitch.euiSwitch--mini
.euiSwitch__button[aria-checked="true"]
.euiSwitch__thumb {
    border-color: #00c1e3;
}

.euiButtonIcon--primary {
    color: #00c1e3;
    border-color: #00c1e3;
}

.euiButtonIcon--primary.euiButtonIcon--fill {
    background-color: #00c1e3;
    border-color: #00c1e3;
    color: #000;
}

.euiButtonIcon--primary.euiButtonIcon--fill:not([class*="isDisabled"]):hover,
.euiButtonIcon--primary.euiButtonIcon--fill:not([class*="isDisabled"]):focus,
.euiButtonIcon--primary.euiButtonIcon--fill:not([class*="isDisabled"]):focus-within {
    background-color: #00c1e3;
    border-color: #00c1e3;
}

.euiButtonIcon--primary:not([class*="isDisabled"]) {
    box-shadow: 0 0.8px 0.8px rgba(0, 0, 0, 0.1), 0 2.3px 2px rgba(0, 0, 0, 0.075);
}

.euiButtonIcon--primary:not([class*="isDisabled"]):hover,
.euiButtonIcon--primary:not([class*="isDisabled"]):focus,
.euiButtonIcon--primary:not([class*="isDisabled"]):focus-within {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25), 0 3.6px 13px rgba(0, 0, 0, 0.175),
    0 8.4px 23px rgba(0, 0, 0, 0.15), 0 23px 35px rgba(0, 0, 0, 0.125);
    background-color: rgba(0, 193, 227, 0.1);
}

.euiButtonIcon--accent {
    color: #0cbdab;
    border-color: #0cbdab;
}

.euiButtonIcon--accent.euiButtonIcon--fill {
    background-color: #0cbdab;
    border-color: #0cbdab;
    color: #000;
}

.euiButtonIcon--accent.euiButtonIcon--fill:not([class*="isDisabled"]):hover,
.euiButtonIcon--accent.euiButtonIcon--fill:not([class*="isDisabled"]):focus,
.euiButtonIcon--accent.euiButtonIcon--fill:not([class*="isDisabled"]):focus-within {
    background-color: #0cbdab;
    border-color: #0cbdab;
}

.euiButtonIcon--accent:not([class*="isDisabled"]) {
    box-shadow: 0 0.8px 0.8px rgba(0, 0, 0, 0.1), 0 2.3px 2px rgba(0, 0, 0, 0.075);
}

.euiButtonIcon--accent:not([class*="isDisabled"]):hover,
.euiButtonIcon--accent:not([class*="isDisabled"]):focus,
.euiButtonIcon--accent:not([class*="isDisabled"]):focus-within {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25), 0 3.6px 13px rgba(0, 0, 0, 0.175),
    0 8.4px 23px rgba(0, 0, 0, 0.15), 0 23px 35px rgba(0, 0, 0, 0.125);
    background-color: rgba(12, 189, 171, 0.1);
}

.euiButtonIcon--success {
    color: #62dea7;
    border-color: #62dea7;
}

.euiButtonIcon--success.euiButtonIcon--fill {
    background-color: #62dea7;
    border-color: #62dea7;
    color: #000;
}

.euiButtonIcon--success.euiButtonIcon--fill:not([class*="isDisabled"]):hover,
.euiButtonIcon--success.euiButtonIcon--fill:not([class*="isDisabled"]):focus,
.euiButtonIcon--success.euiButtonIcon--fill:not([class*="isDisabled"]):focus-within {
    background-color: #62dea7;
    border-color: #62dea7;
}

.euiButtonIcon--success:not([class*="isDisabled"]) {
    box-shadow: 0 0.8px 0.8px rgba(0, 0, 0, 0.1), 0 2.3px 2px rgba(0, 0, 0, 0.075);
}

.euiButtonIcon--success:not([class*="isDisabled"]):hover,
.euiButtonIcon--success:not([class*="isDisabled"]):focus,
.euiButtonIcon--success:not([class*="isDisabled"]):focus-within {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25), 0 3.6px 13px rgba(0, 0, 0, 0.175),
    0 8.4px 23px rgba(0, 0, 0, 0.15), 0 23px 35px rgba(0, 0, 0, 0.125);
    background-color: rgba(9, 240, 136, 0.1);
}

.euiButtonIcon--warning {
    color: #f3d371;
    border-color: #f3d371;
}

.euiButtonIcon--warning.euiButtonIcon--fill {
    background-color: #f3d371;
    border-color: #f3d371;
    color: #000;
}

.euiButtonIcon--warning.euiButtonIcon--fill:not([class*="isDisabled"]):hover,
.euiButtonIcon--warning.euiButtonIcon--fill:not([class*="isDisabled"]):focus,
.euiButtonIcon--warning.euiButtonIcon--fill:not([class*="isDisabled"]):focus-within {
    background-color: #f3d371;
    border-color: #f3d371;
}

.euiButtonIcon--warning:not([class*="isDisabled"]) {
    box-shadow: 0 0.8px 0.8px rgba(0, 0, 0, 0.1), 0 2.3px 2px rgba(0, 0, 0, 0.075);
}

.euiButtonIcon--warning:not([class*="isDisabled"]):hover,
.euiButtonIcon--warning:not([class*="isDisabled"]):focus,
.euiButtonIcon--warning:not([class*="isDisabled"]):focus-within {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25), 0 3.6px 13px rgba(0, 0, 0, 0.175),
    0 8.4px 23px rgba(0, 0, 0, 0.15), 0 23px 35px rgba(0, 0, 0, 0.125);
    background-color: rgba(255, 184, 26, 0.1);
}

.euiButtonIcon--danger {
    color: #ea4f58;
    border-color: #ea4f58;
}

.euiButtonIcon--danger.euiButtonIcon--fill {
    background-color: #ea4f58;
    border-color: #ea4f58;
    color: #000;
}

.euiButtonIcon--danger.euiButtonIcon--fill:not([class*="isDisabled"]):hover,
.euiButtonIcon--danger.euiButtonIcon--fill:not([class*="isDisabled"]):focus,
.euiButtonIcon--danger.euiButtonIcon--fill:not([class*="isDisabled"]):focus-within {
    background-color: #ea4f58;
    border-color: #ea4f58;
}

.euiButtonIcon--danger:not([class*="isDisabled"]) {
    box-shadow: 0 0.8px 0.8px rgba(0, 0, 0, 0.1), 0 2.3px 2px rgba(0, 0, 0, 0.075);
}

.euiButtonIcon--danger:not([class*="isDisabled"]):hover,
.euiButtonIcon--danger:not([class*="isDisabled"]):focus,
.euiButtonIcon--danger:not([class*="isDisabled"]):focus-within {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25), 0 3.6px 13px rgba(0, 0, 0, 0.175),
    0 8.4px 23px rgba(0, 0, 0, 0.15), 0 23px 35px rgba(0, 0, 0, 0.125);
    background-color: rgba(230, 35, 46, 0.1);
}

.euiIcon--success {
    color: #62dea7;
}

.euiIcon--accent {
    color: #0cbdab;
}

.euiIcon--danger {
    color: #ea4f58;
}

.euiIcon--ghost {
    color: #fff;
}

.euiIcon--primary {
    color: #00c1e3;
}

.euiIcon--subdued {
    color: #7a7f89;
}

.euiIcon--text {
    color: #dfe5ef;
}

.euiIcon--warning {
    color: #f3d371;
}

.euiIcon--inherit {
    color: inherit;
}

.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
    border-color: #00c1e3;
    background-color: #00c1e3;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%2829, 30, 36%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
}

.euiCheckbox .euiCheckbox__input:indeterminate + .euiCheckbox__square {
    border-color: #00c1e3;
    background-color: #00c1e3;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Crect width='6' height='6' fill='rgb%2829, 30, 36%29' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square {
    outline: 2px solid currentColor;
    outline-offset: 2px;
    border-color: #00c1e3;
}

.euiTableRow.euiTableRow-isSelected {
    background-color: rgba(0, 193, 227, 0.2);
}

.euiTableRow.euiTableRow-isSelected
+ .euiTableRow.euiTableRow-isExpandedRow
.euiTableRowCell {
    background-color: rgba(0, 193, 227, 0.2);
}

.euiTableRow.euiTableRow-isSelected:hover,
.euiTableRow.euiTableRow-isSelected:hover
+ .euiTableRow.euiTableRow-isExpandedRow
.euiTableRowCell {
    background-color: rgba(0, 193, 227, 0.28);
}

.euiToast--primary {
    border-top: 2px solid #00c1e3;
}

.euiToast--success {
    border-top: 2px solid #62dea7;
}

.euiToast--warning {
    border-top: 2px solid #f3d371;
}

.euiToast--danger {
    border-top: 2px solid #ea4f58;
}

.euiLink.euiLink--primary {
    color: #00c1e3;
}

.euiLink.euiLink--primary:hover,
.euiLink.euiLink--primary:focus,
.euiLink.euiLink--primary:target {
    text-decoration: underline;
}

.euiLink.euiLink--primary:target {
    color: #01aecc;
}

.euiLink.euiLink--primary:focus {
    outline: 2px solid currentColor;
    outline-offset: 2px;
}

.euiLink.euiLink--primary:focus:focus-visible {
    outline-style: auto;
}

.euiLink.euiLink--primary:focus:not(:focus-visible) {
    outline: none;
}

.euiLink.euiLink--success {
    color: #62dea7;
}

.euiLink.euiLink--success:hover,
.euiLink.euiLink--success:focus,
.euiLink.euiLink--success:target {
    text-decoration: underline;
}

.euiLink.euiLink--success:target {
    color: #09ba6a;
}

.euiLink.euiLink--success:focus {
    outline: 2px solid currentColor;
    outline-offset: 2px;
}

.euiLink.euiLink--success:focus:focus-visible {
    outline-style: auto;
}

.euiLink.euiLink--success:focus:not(:focus-visible) {
    outline: none;
}

.euiLink.euiLink--accent {
    color: #0cbdab;
}

.euiLink.euiLink--accent:hover,
.euiLink.euiLink--accent:focus,
.euiLink.euiLink--accent:target {
    text-decoration: underline;
}

.euiLink.euiLink--accent:target {
    color: #0a9083;
}

.euiLink.euiLink--accent:focus {
    outline: 2px solid currentColor;
    outline-offset: 2px;
}

.euiLink.euiLink--accent:focus:focus-visible {
    outline-style: auto;
}

.euiLink.euiLink--accent:focus:not(:focus-visible) {
    outline: none;
}

.euiLink.euiLink--warning {
    color: #f3d371;
}

.euiLink.euiLink--warning:hover,
.euiLink.euiLink--warning:focus,
.euiLink.euiLink--warning:target {
    text-decoration: underline;
}

.euiLink.euiLink--warning:target {
    color: #f3b14c;
}

.euiLink.euiLink--warning:focus {
    outline: 2px solid currentColor;
    outline-offset: 2px;
}

.euiLink.euiLink--warning:focus:focus-visible {
    outline-style: auto;
}

.euiLink.euiLink--warning:focus:not(:focus-visible) {
    outline: none;
}

.euiLink.euiLink--danger {
    color: #ea4f58;
}

.euiLink.euiLink--danger:hover,
.euiLink.euiLink--danger:focus,
.euiLink.euiLink--danger:target {
    text-decoration: underline;
}

.euiLink.euiLink--danger:target {
    color: #891219;
}

.euiLink.euiLink--danger:focus {
    outline: 2px solid currentColor;
    outline-offset: 2px;
}

.euiLink.euiLink--danger:focus:focus-visible {
    outline-style: auto;
}

.euiLink.euiLink--danger:focus:not(:focus-visible) {
    outline: none;
}

.euiNotificationBadge {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    display: inline-block;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    height: 16px;
    min-width: 16px;
    padding-left: 4px;
    padding-right: 4px;
    vertical-align: middle;
    text-align: center;
    transition: all 150ms ease-in;
    cursor: default;
    background: #0cbdab;
    color: #000;
}

.euiStepNumber {
    outline-color: #01aecc;
}

.euiStepNumber .euiStepNumber__icon {
    position: relative;
    top: -1px;
}

.euiStepNumber--small .euiStepNumber__icon {
    top: -1px;
}

.euiStepNumber--complete .euiStepNumber__icon,
.euiStepNumber--danger .euiStepNumber__icon {
    stroke: currentColor;
    stroke-width: 0.5px;
}

.euiStepNumber--default {
    background-color: #01aecc;
    color: #000;
    outline-color: #000 !important;
}

.euiStepNumber--complete {
    background-color: #0cbdab;
    color: #000;
    outline-color: #000 !important;
}

.euiStepNumber--warning {
    background-color: #f3d371;
    color: #000;
    outline-color: #000 !important;
}

.euiStepNumber--danger {
    background-color: #ea4f58;
    color: #000;
    outline-color: #000 !important;
}

.euiStepNumber.euiStepNumber--incomplete {
    background-color: transparent;
    color: #dfe5ef;
    border: 2px solid #343741;
}

.euiStepNumber.euiStepNumber--incomplete .euiStepNumber__number {
    display: unset;
    position: relative;
    top: -2px;
}

.euiStepNumber--small {
    width: 24px;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    border-radius: 24px;
    text-align: center;
    color: #1d1e24;
    background-color: #01aecc !important;
    font-size: 12px;
    font-weight: 500;
}

.euiStepNumber {
    width: 32px;
    height: 32px;
    display: inline-block;
    line-height: 32px;
    border-radius: 32px;
    text-align: center;
    color: #1d1e24;
    background-color: #01aecc;
    font-size: 14px;
    font-weight: 500;
}
